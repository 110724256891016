<template>
  <!-- 活动详情页面 -->
  <moe-page title="活动商品">
    <moe-card class="mb-20" :autoHeight="true">
      <moe-describe-list title="活动信息" :col="2">
        <moe-describe-item label="活动名称" class="wsn">{{ activityDetail.name }}</moe-describe-item>
        <moe-describe-item label="活动位置" class="wsn">{{ $moe_format.getActivityAliasList(activityDetail.alias) }}</moe-describe-item>
        <!-- <moe-describe-item label="展示风格" class="wsn" v-if="activityDetail.style">{{ $moe_format.getActivityAliasStyleList(activityDetail.style.mode) }}</moe-describe-item> -->
        <moe-describe-item label="活动介绍" class="wsn">{{ activityDetail.content }}</moe-describe-item>
        <moe-describe-item label="创建时间" class="wsn">{{ $moe_time.getTimeStamp(activityDetail.createTime) }}</moe-describe-item>
      </moe-describe-list>

      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <!-- 商品列表 -->
        <moe-table ref="activityTable" :data="goodsList" url="" :params="{ pageNum: 1 }" :mode="false" :numberShow="false" :showPage="false">
          <template slot="tool">
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd()">添加商品</el-button>
          </template>
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="ml-10 df fdc aifs">
                  <p class="fwb tal">{{ row.name }}</p>
                  <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" min-width="150">
            <template slot-scope="{ row }">
              {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="stock" label="库存" width="130" />
          <el-table-column label="操作" min-width="350" fixed="right">
            <div class="moe-table__btns df aic jcc" slot-scope="{ row, $index }">
              <template v-if="$index > 0">
                <el-button type="success" size="mini" icon="el-icon-upload2" @click="handleClick('topping', row, $index)">置顶</el-button>
                <el-button type="primary" size="mini" icon="el-icon-top" @click="handleClick('top', row, $index)">上移</el-button>
              </template>
              <el-button v-if="$index < (goodsList.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleClick('bottom', row, $index)">下移</el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleClick('delete', row, $index)">移除</el-button>
            </div>
          </el-table-column>
        </moe-table>
      </div>

      <moe-form :showBack="false" :defaultVerify="false">
        <template slot="tool">
          <el-button type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
          <el-button type="primary" icon="el-icon-upload2" :loading="paramsLoad" @click="updateActivityGoods()">提交</el-button>
        </template>
      </moe-form>
    </moe-card>

    <goodsDialog
      :showDialog.sync="showDialog"
      :defaultSelectIds="goodsList && goodsList.length ? goodsList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityGoods',
  components: {
    goodsDialog
  },
  data() {
    return {
      goodsList: [],   //商品列表
      activityDetail: {}, //用户信息,
      activityParams: {
        id: '',
        name: '',     //活动名称
        pageNum: 1,   //当前页
        pageSize: 999, //每页条数
      },
      showDialog: false,
      paramsLoad: false,
    };
  },
  mounted() {
    this.activityParams.id = this.$route.query.id;
    //获取用户详情
    this.getActivityDetail();
    this.getActivityGoodsDetail();
  },
  methods: {
    handleAdd() {
      this.showDialog = true;
    },
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.goodsList.find(({ goodsId }) => item.id === goodsId)

        if (!findItem) {
          this.goodsList.push({
            ...item,
            goodsId: item.id,
            id: '',
          });
        }
      })

      this.showDialog = false;
    },

    handleClick(type, { name }, index) {
      switch (type) {
        case 'delete':
          this.$moe_layer.confirm(`您确定要移除当前选项"${name}"吗？`, () => {
            this.goodsList.splice(index, 1);
          });
          break;
        case 'topping':
          this.$moe_layer.confirm(`您确定要置顶当前选项"${name}"吗？`, () => {
            const row = this.$moe_lodash.cloneDeepData(this.goodsList[index]);
            this.goodsList.splice(index, 1);
            this.goodsList.unshift(row);
            this.$moe_msg.success('置顶成功,提交后更新');
          });
          break;
        case 'top':
          this.$moe_layer.confirm(`您确定要上移当前选项"${name}"吗？`, () => {
            this.goodsList.splice(index - 1, 2, this.goodsList[index], this.goodsList[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':
          this.$moe_layer.confirm(`您确定要下移当前选项"${name}"吗？`, () => {
            this.goodsList.splice(index, 2, this.goodsList[index + 1], this.goodsList[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
      }
    },

    /** 更新活动下商品 */
    updateActivityGoods() {
      this.paramsLoad = true;
      this.$moe_api.GOODS_API.updateActivityGoods({
        id: this.activityDetail.id,
        goodsIds: this.goodsList.map(({ goodsId }) => goodsId)
      }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('操作成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.paramsLoad = false;
      })
    },

    /** 获取活动商品列表 */
    getActivityGoodsDetail() {
      this.$moe_api.GOODS_API.activityGoodsDetail(this.activityParams).then((data) => {
        if (data.code == 200) {
          this.goodsList = data.result.map((item) => {
            return {
              ...item,
              goodsId: item.id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },

    /**
     * 获取活动详情
     **/
    getActivityDetail() {
      const id = this.$route.query.id;
      // 调用获取活动详情API
      this.$moe_api.GOODS_API.activityAmendDetail({ id }).then((data) => {
        if (data.code == 200) {
          let a = data.result;
          this.activityDetail = {
            id: a.id,
            name: a.name, //活动名称
            alias: a.alias,
            content: a.content,  //活动介绍
            createTime: a.createTime,   //建立时间
            style: a.style ? JSON.parse(a.style) : { mode: 'default' },
          };
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.dialog_content {
  display: flex;
  flex-direction: column;
  height: 53vh;
}
</style>